
.uni-login {
    z-index: 7;
    /*min-width: 100vw;*/
    /*min-height: 100vh;*/
    width: 100%;
    height: 100%;
    background-color: #5b5c5c;
    opacity: .4;
    position: fixed;
    left: 0;
    top: 0;

}

.login {

    z-index: 9;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 520px;
    /*border: 1px solid salmon;*/
    border-radius: 13px;
    display: flex;
    overflow: hidden;
    background-color: white;
}

.login .left {
    width: 320px;
    background:url("../assets/pic_denglu.png");
}

.login .right {
    padding-top: 90px;
    padding-left: 99px;
    display: flex;

    flex-direction: column;
}
.login .right .close{
    height: 20px;
    width: 20px;
    /*border: 1px slategrey solid;*/
    position: absolute;
    right: 44px;
    top: 44px;
    cursor: pointer;
;
}
.login .right h2 {
    margin: 0;
    padding: 0;
}

.login .right h6 {
    margin-top: 22px;
    padding: 0;
    margin-bottom: 22px;

    font-size: 14px;
    font-weight: 500;
    color: #a7b3c7;
    line-height: 30px;

}

.login .right .input {
    border-bottom: 1px rgba(187, 196, 206, 0.6) solid;
    height: 33px;
    width: 260px;
    margin-bottom: 11px;
}

.login .right .bt {
    /*border: 1px slategrey dashed;*/
    height: 44px;
    width: 260px;
    margin-top: 22px;
    margin-bottom: 22px;
}