
.loginForm {
    text-align: left;
    /*padding: 30px 5px 41px 5px;*/
    /*margin: auto;*/
    z-index: 999;
    display: flex;
    flex-flow: column;
    background: #fff;
    border-radius: 6px;
}
.loginPageForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 30px 45px 41px 45px;
  height: 513px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  text-align: left;
  margin: auto;
  z-index: 999;
  display: flex;
  flex-flow: column;
  background: #fff;
  border-radius: 6px;
}
.formTitle{
    font-size: 1rem;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
}

.KM{
    padding: 2px 0 24px 0;
    color: #b2b2b2;
}

.userIcon{
    width: 30px;
    height: 26px;
    padding-right: 4px;
    
}
.userIconDiv{
    position: relative;
    padding-left: 4px;
    width: 50px;
}
.userIconDiv::before{
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    width: 1px;
    height: 60%;
    content: '';
    background: #a7a9aa;
    top: 20%;
    right: 10px;
}
.suffix{
    position: relative;
    padding-right: 4px;
    padding-left: 14px;
    
    /* width: 80px; */
}
.suffix::before{
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    width: 1px;
    height: 60%;
    content: '';
    background: #a7a9aa;
    top: 20%;
    left: 0px;
}
.codeImg{
    width: 72px;
    height: 30px;
}