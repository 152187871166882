footer{
    display: flex;
    flex-direction: column;
}
footer .copyright{
    border-top: 3px solid #707070;
    height: 77px;
    background: #1f2f44;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;

}
.footer{
    width: 100%;
    /*border: 1px solid saddlebrown;*/
    box-sizing: border-box;
    height: 222px;
    display: flex;
    padding: 59px;
    justify-content: center;
    background: #1f2f44;
    /*align-items: center;*/

}

.footer div{
    width: 555px;
    /*border: 1px solid #ffffff;*/
    /*flex: 1;*/
    background: #1f2f44;
    margin-right: 22px;
    display: flex;
    flex-direction: column;
    padding: 11px;
}

.footer div hr {
    width: 122px ;
    border: 1px solid #ffffff;
    margin: 0 0 11px;
}
.footer div p {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    margin-bottom: 13px    ;

}
.footer div a {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #dce3f0;
    text-decoration: none;
    margin-bottom: 13px    ;
}