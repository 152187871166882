.container{
    background: #eff2f4;
    min-width: 1440px;
    padding: 54px 0;
}
.bqicon_image{
  margin: 20px 0;
  width: 180px;
  height: 36px;
}
.item-container{
    /*position: relative;*/
    /*z-index: 9;*/
    /*padding: 80px 23px;*/
    /*width: 200px;*/
    /*height: 420px;*/
    /* box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.04);
    border: 1px solid rgba(0,0,0,0.04); */

    width: 288px;
    height: 400px;
    transition-duration: .2s;
    border-radius: 20px;
    margin: 19px;
    padding: 60px;
}

.item-container:hover{
    background: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(11,77,245,0.08);
}

.list{
    display: flex;
    min-width: 1000px;
    justify-content: space-between;
}

.item-container:hover::before {
  z-index: -1;
  background-color: white;
  border: .124rem solid #ffd031;
  /* box-shadow: 0px 1px 10px 0px #ffd031; */
  /*transform: skewX(-4deg);*/
  content: ''; 
  position: absolute; 
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0;
}



.info-item-image{
    flex: 0 0 40%;
    width: 80px;
    height: 80px;
}
.info-item-title{
    flex: 0 0 15%;
    margin-top: 20px;
    font-size: 1.3rem;
    font-family: Microsoft YaHei, Microsoft YaHei-Semibold;
    /* font-weight: 600; */
}
.info-item-value{
    flex: 0 0 60%;
    margin-top: 28px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    width: 200px;
    text-align: start;
color: #566176;
    font-size: 14px;
}
.info-value{
    margin-top: 20px;
    margin-bottom: 60px;
    font-size: 1.3rem;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    color: #5b5c5c;
}

