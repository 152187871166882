
.head {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding-right: 33px;
    padding-left: 33px;
    box-sizing: border-box;
    min-width: fit-content;
    height: 66px;
    z-index: 33;

    box-shadow: rgba(99, 99, 99, 0.08) 0px 2px 8px 0px;
}


.head .logo {
    width: 180px;
    height: 48px;
    color: #5b5c5c;
    /*background-color: #7157FF;*/
    background: url("../assets/logo.png");
    background-repeat: no-repeat;
}

.head .av {
    font-size: 16px;
    color: #1F3045;
    font-weight: 444;
}

.head .sel {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: 1px salmon solid;*/
    padding-left: 33px;
    padding-right: 33px;
}

.head .sel a {
    margin: 11px;
    color: #5b5c5c;
    font-weight: 555;
}

.pageHeader .head-wrapper {
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0px 20px;
}

.pageHeader .space {
    flex: 1;
}

.space-right {
    color: rgba(255, 255, 255, 0.65);
}

.pageHeader .span-username {
    margin-left: 20px;
    color: white;
    font-size: 8px;
}