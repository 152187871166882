.payment {
    min-width: fit-content;
    /*min-height: 100vh;*/
    /*background-color: #321aec;*/
    display: flex;
    justify-content: center;
    padding: 11px;

}


.row-center{
    display: flex;
    align-items: center;
}
.mgr11{
    margin-right: 11px;
}

.payment main {
    padding: 11px;
    /*min-width: 1323px;*/
    width: fit-content;
    /*background-color: #d33333;*/
    border-radius: 5px;

    /*border: 11px rgba(30, 28, 28, 0.65) solid;*/
    /*width: 1111px;*/
    /*min-height: 777px;*/
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;


}

.payment main .left {
    /*padding: 21px 21px 21px 21px;*/
    width: 927px;
    min-width: 927px;
    padding: 33px;
    /*border: 1px rgba(19, 222, 120, 0.65) solid;*/
    margin-right: 11px;
    background-color: #ffffff;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
}

.payment main .left header {
    width: 100%;
    /*border: 1px rgba(30, 28, 28, 0.65) solid;*/
    /*background-color: #d21616;*/
}

.payment .tbody {
    display: flex;
    align-items: center;
}

.payment .title {
    font-size: 24px;
    color: #1F3045;
    font-weight: 777;
    padding: 22px 0;
    /*margin-bottom: 10px;*/

    /*margin-bottom: 111px ;*/
}

.payment .act {
    width: 100%;
    display: flex;
    /*background-color: #c2bfd9;*/
    flex-wrap: wrap;
    justify-content: space-between;
}

.payment .act .im {
    height: 208px;
    width: 274px;
    margin-bottom: 25px;
    border-radius: 10px;
    position: relative;

    background: #f5f7fc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.payment .act .im .tj {

    /*width: 11px;*/
    /*height: 11px;*/
    /*background-color: #9b6c43;*/
    position: absolute;
    right: -3px;
    top: -3px;
}


.payment .act .im .val {
    height: 38px;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: #9b6c43;
    line-height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.payment .act .im .dou {
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #fc5801;
    line-height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;


}


.payment .act .im .zhe {
    width: 186px;
    height: 32px;
    background: linear-gradient(270deg, #d3def2, #f0f4fc);
    border-radius: 16px;


    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;


}

.payment .gold-im {
    opacity: 0.74;
    background: #fdf7f2;
}

.payment main .r {
    width: 333px;
    min-width: 333px;
    /*border: 1px rgba(30, 28, 28, 0.65) solid;*/
    /*border-radius: 11px;*/
    display: flex;

    flex-direction: column;

    /*background-color: #167dd2;*/
}

.payment main .r .account {
    height: 198px;
    min-width: 333px;
    background-color: #ffffff;
    border-radius: 11px;
    padding-left: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    /*align-items: center;*/
}



.payment main .r  .dt{
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #1f3045;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 10px;

}

.payment main .r .account .dv{
    margin-bottom: 10px;

    width: 53px;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: left;
    color: #ff8227;
    line-height: 24px;

}

.payment main .r .pay{
    margin-top: 11px;
    min-width: 333px;
    background-color: #ffffff;
    border-radius: 11px;
    padding: 13px 40px;
    flex: 1;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
}



