.click-btn{
    color:rgba(255,255,255,0.65);
}
.click-btn:before{
    position: absolute;
    left: 0;
    top: 0;
    right:0;
    bottom: 0;
    border: 1px solid gold;
    border-radius: 20px;    
}
// .clickbtn:hover:before{
//     content: '';
// }
.click-btn:active:before{
    background: transparent;    
    content: '';
}