.mb3{
    margin-bottom: .9rem !important;
    background: #eff2f4;
}

.mb3 .ant-input { 
    background: #eff2f4;
    background-color: #eff2f4!important;
}
.mb3 .ant-form-item-explain-error { 
    background: white;
    background-color: white!important;
}