.fileBtn{
    padding: .375rem 20px!important;
}
@media screen and (max-width:1360px){
    .fileBtn{
        padding: .375rem 24px!important;
    }
}



.img{
    margin: auto 0;
    width: 15px;
    height: 15px;
    margin-right: 8px;
}