
main , main section {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

main section h4 {
    margin-top: 133px;
    font-size: 40px;
    font-weight: 999;
}
main section p {
    font-size: 16px;
    color: #566176;
    /*width: 454px;*/
    text-align: center;
}
main section .item{
    margin-top: 22px;
    /*background-color: #707070;*/
    width: 729px;
    height: 366px;
    border-radius: 12px;
}


.hezuo{
    display: flex;
    width: 777px;
    /*background-color: aqua;*/
    flex-direction: column;
}

.hezuoft{
    width: 100%;
    /*border: 1px solid saddlebrown;*/
    box-sizing: border-box;
    height: 222px;
    display: flex;
    padding: 39px;
    justify-content: center;
    /*align-items: center;*/

}

.hezuoft div{
    width: 555px;
    /*border: 1px solid #ffffff;*/
    /*flex: 1;*/
    /*background: #1f2f44;*/
    margin-right: 22px;
    display: flex;
    flex-direction: column;
    padding: 11px;
}

.hezuoft div hr {
    margin: 0;
    width: 122px ;
    border: 1px solid #1f2f44;
    margin-bottom: 11px;
}
.hezuoft div p {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #1f2f44;
    margin-bottom: 13px    ;

}
.hezuoft div a {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #1f2f44;
    text-decoration: none;
    margin-bottom: 13px    ;
}