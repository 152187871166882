.radioGameIndex{
    text-align: center;
    width: 50px;
    height: 32px;
    margin-right: 10px;
    /* border-radius: 4px; */
    margin-bottom: 4px;
}
.select{
    height: 34px;
    border-radius:16px;
    border: 1px solid #d9d9d9;
}
.catListFooter{
    font-size: 16px;
    height: 100px;
    line-height: 100px;
    justify-content: space-between;
    display: flex;
    width: 100%;
}
.footerText{
    color: #FC6F01;
}
.catListFooterLeft{
    display: flex;
    min-width: 400px;
}

.catListFooterCenter{
    display: flex;
    /* width: 100%; */
}
.jbicon_1{
    width: 20px;
    margin-top: 4px;
    /* height: 50px; */
}
.buy{
    display: flex;
    min-width: 420px;
}