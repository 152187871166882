.title{
    padding: 20px 0;
    width:100%;
    color: rgba(255,255,255,0.85);
    font-weight: 700;
    font-family:'Microsoft YaHei, Microsoft YaHei-Bold';
    position: relative;
}
.value{
    padding-bottom: 20px;
    width:100%;
    color: rgba(255,255,255,0.65);
    font-family:'Microsoft YaHei, Microsoft YaHei-Bold';
}
.title::before {
    background: #707070;;
    content: ''; 
    height: 1px;
    width: 120px;
    position: absolute; 
    top: 0%;
    left: 0px; 
}