.bqicon{
    margin: 20px 0;
    width: 140px;
    height: 30px;
}

.labalFont {
    width: 156px;
    height: 37px;
    font-size: 26px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: center;
    color: rgba(0,0,0,0.85);
    line-height: 43px;
}

.valueFont {
    width: 156px;
    height: 27px;
    font-size: 27px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: rgba(0,0,0,0.65);
    line-height: 45px;
 }