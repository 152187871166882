:root{
  --primary-cr: #7157ff;
}

[mgr='at']{
  margin-right: auto;
}

.my-des {
  .ant-radio-button-wrapper{
    border: none!important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color:initial!important;
    border: none!important;
  }


  .ant-input-affix-wrapper{
    border-radius: 5px !important;
    border: none!important;
  }

  .ant-select{
    background: #ffffff!important;
    border: none!important;
  }

  .ant-input-affix-wrapper-focused{
    border-radius: 5px !important;
    border: none!important;
  }
  .ant-input-search-button{
    border-radius: 5px !important;
    margin-left: -77px!important;
    border: none!important;
    width: 77px!important;
    z-index: 5!important;
    position: absolute;
    right: -2px;
    top: -1px;
  }


  .ant-picker{
    border-radius: 5px;
    border: none;
  }

  .ant-select-selector{
   border: none!important;
  }

}

.bg{
  background: #F1F4FD;
  width: 100%;
  padding-left: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.md-hfqhxf-bt .ant-btn{
  border-radius: 8px!important;
}
.ant-pagination-item-active a {
  color: rgb(254, 254, 255) !important;
  //background: var(--ant-primary-color);
  background: linear-gradient(33deg,#6954f8 0%, #800bf5 100%);
}

#dti{
  .ant-table-thead > tr > th{
    font-weight: 999 !important;
    color: aliceblue!important;
    background:var(--primary-cr)!important;
  }

  .ant-table-body::-webkit-scrollbar{
    //width: 2px!important;
    //display: none!important;
  }

  .ant-table-cell-scrollbar{
    box-shadow: none;
  }
  .ant-table table{
    //width: initial;
  }

}
.ant-modal-header{
  background: #7157ff !important;
  border-top-left-radius: 10px!important;
  border-top-right-radius: 10px!important;
}
.ant-modal-content{
  border-radius: 33px 33px 10px 10px!important;
  //border-radius: 10px!important;
  //overflow: hidden!important;
}

.ant-modal-title{
  color:aliceblue!important;
  font-weight: 777 !important;
}

.ant-modal-close-x{
  color: white!important;

}

body{
  background: #F1F4FD!important;
  devf:'--body-color-1223f3v2';

}


//////////for */////////////

  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgb(248, 248, 250);
  }

  *::-webkit-scrollbar-track {
    background: rgba(113, 87, 255, 0.15);
  }



  *::-webkit-scrollbar-thumb {
    background: linear-gradient(33deg, rgba(56, 132, 255, 0.84) 0%, rgba(128, 11, 245, 0.87) 100%) !important;
    border-radius: 12px;
    transition-duration: .28s;
    box-shadow: 0 5px 77px rgb(50, 26, 236);
  }

  *::-webkit-scrollbar-thumb:hover {
    border-radius: 12px;
    box-shadow: 0 5px 22px rgb(113, 87, 255);
  }

  *::-webkit-scrollbar-corner {
    background-color: rgb(113, 87, 255);
  }


  *::-webkit-scrollbar-thumb:active {
    background-color: rgb(113, 87, 255);
    border-radius: 10px;
  }


.rc-virtual-list-scrollbar-thumb{
  background: linear-gradient(33deg, rgba(56, 132, 255, 0.84) 0%, rgba(128, 11, 245, 0.87) 100%) !important;
  border-radius: 12px;
  transition-duration: .28s;
  box-shadow: 0 5px 77px rgb(50, 26, 236);
  width: 5px;
  height: 5px;
}
