.map{
    width: 100%;
    /*border: 1px saddlebrown solid;*/
    position: relative;

}

.map img{
    width: 100%;
}

.xp:after{
    content: '';
    /*position: absolute;*/
    /*top:333px;*/
    /*left: 333px;*/
    position: absolute;
    top:-31px;
    /*left: 66px;*/
    opacity: 0;
    width: 66px;
    height: 66px;
    /*border: 1px solid #131f19;*/
    box-shadow: 0 0 0px 4px rgb(131, 0, 255);
    border-radius: 50%;
    display: grid;
    place-items: center;
    animation: mapani 3s infinite;
    animation-delay: 0s;
}

.xp {
    font-size: 10px;
    font-weight: 333;
    position: absolute;
    /*top: 62%;*/
    /*left: 48%;*/

    /*background-color: #8300ff;*/
    display: grid;
    place-items: center;
    width: 58px;
    /*height: 58px;*/
    border-radius: 50%;
    /*filter: blur(2px);*/
}

.xp:before {
    content: '';
    border-radius: 50%;
    width: 2px;
    height: 2px;
    /*position: absolute;*/
    transform: scale(2);
    background-color: #9932fc;
    animation: brth 3s infinite;
    /*filter: blur(1px);*/
}
.xp:hover:before{
    transform: scale(5);
    /*width: 12px;*/
    /*height: 12px;*/
}

@keyframes brth{
    0% {
        opacity: 0;
    }
    77% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes mapani {
    0% {

        transform: scale(.1);
        opacity: .0;
    }
    11% {
       /*transform: scale(1);*/
        opacity: .3;
    }
    66% {

        transform: scale(1);
        opacity: 0.01;
    }
    100% {

        transform: scale(1);
        opacity: 0;
    }

}