.bqicon{
    margin: 20px 0;
    width: 150px;
    height: 30px;
}
.containerTitle{
    margin: 10px 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 500;
    color: #595959;
}
.imgContainer{
    /* margin-top: 40px; */
    display: 'flex';
    width: '1200px';
    justify-content: 'space-between';
}
.listImg{
  margin: auto 0;
  display: block;
  width: 220px;
  height: 45px;
}
.hticon{
    width: 20px;
    height: 20px;
}
.hticonL{
    margin-right: 126px;
    transform:rotate(90deg);
    -ms-transform:rotate(90deg); 	/* IE 9 */
    -moz-transform:rotate(90deg); 	/* Firefox */
    -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
    -o-transform:rotate(90deg); 	/* Opera */
}

.hticonR{
    margin-left: 100px;
    transform:rotate(-90deg);
    -ms-transform:rotate(-90deg); 	/* IE 9 */
    -moz-transform:rotate(-90deg); 	/* Firefox */
    -webkit-transform:rotate(-90deg); /* Safari 和 Chrome */
    -o-transform:rotate(-90deg); 	/* Opera */
}